<ng-container>
  <div class="content-container flex justify-center md:justify-start">
    <div class="w-82 md:w-210">
      <h1 class="text-h1-360 md:text-h1-heading text-primary-base uppercase">
        Допомогти
      </h1>

      <div class="flex-wrap mb-30 xl:mb-20 mt-5" id="DonateForm">
        <div class="flex flex-col xl:flex-row">
          <app-tabs>
            <app-tab [position]="'first'" [tabTitle]="'Разова допомога'">
              <app-single-help></app-single-help>
            </app-tab>
            <app-tab [position]="'last'" [tabTitle]="'Щомісячна допомога'">
              <app-subscribe-help></app-subscribe-help>
            </app-tab>
          </app-tabs>
          <div class="w-82 md:w-210 xl:w-95 xl:ml-5 mt-10 xl:mt-19.5">
            <div
              class="flex flex-col bg-gray-card rounded-30 pl-6 xl:px-5 pt-9 xl:pt-10 xl:pb-5">
              <ng-container *ngIf="reportsCards.length">
                <h2
                  [innerHTML]="'<div>На що підуть ці гроші?</div>'"
                  class="text-h2-360 md:text-h2-heading text-primary-base uppercase xl:pr-19.5"></h2>
                <div
                  *ngFor="let transferToReportsItem of reportsCards"
                  class="flex flex-row items-center py-8.5 border-b-2 border-b-gray-stroke last:border-b-0 xl:last:pb-2.5">
                  <img
                    alt=""
                    class="mr-4"
                    src="{{ transferToReportsItem.imageURL }}" />
                  <div
                    [innerHTML]="transferToReportsItem.bodyText"
                    class="w-51.75 md:w-182.5 text-gray-dark text-smaller-main md:text-main-text"></div>
                </div>
              </ng-container>
            </div>
            <div class="mt-4.5 w-82 md:w-102.5 xl:w-95 h-12">
              <app-link-button
                [linkButtonType]="'Secondary'"
                [routerLink]="reportLink"
                [title]="'Переглянути звіти'">
              </app-link-button>
            </div>
          </div>
        </div>
      </div>
      <section
        class="mb-15 md:mb-30 xl:w-295"
        id="Requisites"
        *ngIf="mainPaidCardsArray.length">
        <app-slider
          [SlidesArray]="mainPaidCardsArray"
          [slide]="true"
          [title]="'Реквізити рахунків фонду у гривні та валюті'"
          id="payment"></app-slider>
      </section>

      <section class="mb-26.5 md:mb-30 xl:w-295" id="Alternative">
        <div class="flex flex-col justify-between mb-10 items-start">
          <h3
            class="text-h2-360 md:text-h2-heading text-primary-base xl:w-145 mb-10">
            {{ 'Інший спосіб зробити переказ' | uppercase }}
          </h3>
          <div
            class="flex flex-row flex-wrap md:grid md:grid-cols-[380px_380px_380px] place-content-center gap-5">
            <app-card-alternative
              *ngFor="let card of alternativeCardsArray"
              [card]="card"></app-card-alternative>
          </div>
        </div>
      </section>

      <section class="flex-col xl:w-295">
        <div class="mb-10">
          <h3 class="text-h2-360 md:text-h2-heading text-primary-base">
            {{ 'Запитання та відповіді' | uppercase }}
          </h3>
        </div>
        <app-accordion
          [AccordionsList]="AccordionData"
          [classList]="'mb-30 xl:w-full'"
          [options]="{ multi: false }"></app-accordion>
      </section>
    </div>
  </div>
</ng-container>
